import React, { useState, useEffect } from 'react';
import { UserOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Input, Button, Modal, Typography, message } from 'antd';
import Logo from './logo';
import Cookies from 'js-cookie';

const { Title, Paragraph, Link } = Typography;

const MailchimpPopup = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();
  const modalStatus = Cookies.get('modalStatus');
  useEffect(() => {
    if (!modalStatus) {
      const timer = setTimeout(() => {
        setIsModalOpen(true);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [modalStatus]);
  
  const styles = {
    container: {
      backgroundColor: '#feeeec',
    },
    typographyContainer: {
      textAlign: 'center',
    },
    modalInput: {
      marginTop: '16px',
    },
    submitButton: {
      width: '100%',
      height: '38px',
      backgroundColor: 'black',
      borderColor: 'black',
      color: 'white',
      marginTop: '16px',
    },
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    if(!modalStatus){
      Cookies.set('modalStatus', 'closed', { expires: 7 });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
  
    try {
      const response = await fetch('https://chillfitrave.com/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, firstName: name, phoneNumber: phone }),
      });
  
      const data = await response.json();
  
      if (data.status === 'subscribed') {
        messageApi.open({
          type: 'success',
          content: data.message,
        });
      } else if (data.status === 'already_subscribed') {
        messageApi.open({
          type: 'info',
          content: data.message,
        });
      } else {
        messageApi.open({
          type: 'warning',
          content: data.message,
        });
      }
    } catch (error) {
      messageApi.open({
        type: 'error',
        content: 'Something went wrong, please try again.',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
        <Typography style={styles.typographyContainer}>
          <Link href='/'>
            <Logo />
          </Link>
          <Title level={3}>NEVER MISS A NEW OR A DEAL</Title>
          <Paragraph>Subscribe for 15% OFF for your first order</Paragraph>
        </Typography>
        <div style={styles.modalInput}>
          <Input size="large" placeholder="First name" prefix={<UserOutlined />} value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div style={styles.modalInput}>
          <Input size="large" placeholder="E-mail address" prefix={<MailOutlined />} value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div style={styles.modalInput}>
          <Input type="phone" size="large" placeholder="Phone number" prefix={<PhoneOutlined />} value={phone} onChange={(e) => setPhone(e.target.value)} />
        </div>
        <div>
          {contextHolder}
          <Button type="primary" style={styles.submitButton} onClick={handleSubmit} loading={isSubmitting}>
            Subscribe
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default MailchimpPopup;
